import { PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import React, { Fragment, useRef } from 'react';
import MyDocument from '../components/ui/DownloadPDF';
import "./ThankYou.scss";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';
import { PropTypes } from 'prop-types';
import { getBookingDetailsById } from '../actions/booking';
import { useEffect } from 'react';
import Spinner from './../components/ui/Spinner';
import { loadPaymentData } from './../actions/booking';


const ThankYou = ({ getBookingDetailsById, loadPaymentData, auth: { isAuthenticated, user }, booking: { bookingDetails, loading } }) => {

    const id = useParams();
    const navigate = useNavigate();
    console.log(id.thankyou)

    useEffect(() => {
        loadPaymentData(id.thankyou);
    }, [loadPaymentData])
    useEffect(() => {
        getBookingDetailsById(id.thankyou);
    }, [getBookingDetailsById])

    const formdata = bookingDetails;

    console.log(bookingDetails)

    const options = {
        timeZone: 'Asia/Kolkata', // Set the time zone to IST
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    const goBookingHistory = () => {
        localStorage.removeItem("booking details");
        navigate(isAuthenticated ? '/booking-history' : '/')
    }

    // const [instance, setInstance] = usePDF({ document: <MyDocument bookingDetails={bookingDetails} user={user} /> })
    // console.log(<MyDocument bookingDetails={bookingDetails} user={user} /> && instance.url)
    // const handlePrint = async () => {
    //     try {
    //         // Wait for the PDF to be ready before attempting to open it
    //         await new Promise(resolve => setTimeout(resolve, 2000)); // Adjust the delay if needed

    //         const printWindow = window.open(instance.url);

    //         if (instance.url && !loading && bookingDetails) {
    //             printWindow && printWindow.print();
    //         } else {
    //             throw new Error("Invalid conditions for printing");
    //         }
    //     } catch (error) {
    //         console.error("Error while handling print:", error);
    //         // You can add additional error handling or alert the user about the error
    //     }
    // };

    const pdfDocument = <MyDocument bookingDetails={bookingDetails} user={user} />;

    const [instance, setInstance] = usePDF({
        document: pdfDocument,
    });

    console.log(instance.url)

    const handlePrint = async () => {
        const printWindow = window.open(instance.url);
        printWindow.print();
    };



    return loading || bookingDetails == null ? <Spinner /> : (
        <Fragment>

            <div id='thank-you'>
                <img className='thank-you-bg' src='/images/thankyou/sea-bg.jpg' alt='sea-background'></img>
                <p className='text-center mb-3 m-sm-0'>Thank you for making payment</p>
                <div id='booking-success'>
                    <h1 className='booking-success-text text-center p-0'><img className='success-img' src='/images/thankyou/success.png' alt='successfully-booked'></img> Your Booking Was Successful!</h1>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <div>
                        <PDFDownloadLink className="pt-3 download-bottom-btn" document={<MyDocument bookingDetails={formdata} user={user} />} fileName={`SeaWalk in Andaman Ticket.pdf`}>
                            {({ blob, url, loading, error }) =>
                                loading ? 'Download PDF' : <button>Download PDF <img style={{ width: "25px" }} className='ms-2' src='/images/thankyou/download-white.png' alt='download'></img></button>
                            }
                        </PDFDownloadLink>
                    </div>
                    {/* <button className="print-pdf-button" type="button" onClick={handlePrint}>
                        Print PDF
                    </button> */}
                </div>

                <br></br>
                <div id='ticket-details'>
                    <h2 className='text-center title'>Sea Walking in Andaman Islands</h2>
                    <h5 className='m-0 text-center sub-title'>Ticket Booked for {formdata?.no_of_tourist} Persons</h5>
                    <br></br>
                    <div>
                        <p className='m-0 text-center booking-id'>Booking ID: {formdata?.booking_id}</p>
                        <p className='m-0 text-center booking-id'>Booked On: {formdata?.date_time}</p>
                    </div>
                    <div className='row m-0 pt-3 pb-4'>
                        <div className='col-sm-6 col-md-7 p-0'>
                            <div id='user-details'>
                                <div className='row m-0'>
                                    <div className='col-6 col-md-3'>
                                        <p className='m-0'>Name:</p>
                                    </div>
                                    <div className='col-6 col-md-9'>
                                        <p className='m-0'>{user?.user_name}</p>
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-6 col-md-3'>
                                        <p className='m-0'>Email ID:</p>
                                    </div>
                                    <div className='col-6 col-md-9'>
                                        <p className='m-0'>{user?.email}</p>
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-6 col-md-3'>
                                        <p className='m-0'>Phone No:</p>
                                    </div>
                                    <div className='col-6 col-md-9'>
                                        <p className='m-0'>{user?.phone_number}</p>
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-6 col-md-3'>
                                        <p className='m-0'>City:</p>
                                    </div>
                                    <div className='col-6 col-md-9'>
                                        <p className='m-0'>{user?.city}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-5 p-0'>
                            <div id='booking-details'>
                                <div className='row m-0'>
                                    <div className='col-6 col-sm-7'>
                                        <p className='m-0'>Sea Walk Date:</p>
                                    </div>
                                    <div className='col-6 col-sm-5'>
                                        <p className='m-0'>{formdata?.seawalk_date}</p>
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-6 col-sm-7'>
                                        <p className='m-0'>Slot (Sea Walk Time):</p>
                                    </div>
                                    <div className='col-6 col-sm-5'>
                                        <p className='m-0'>{formdata?.booked_slot}</p>
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-6 col-sm-7'>
                                        <p className='m-0'>No.of Travellers:</p>
                                    </div>
                                    <div className='col-6 col-sm-5'>
                                        <p className='m-0'>{formdata?.no_of_tourist} Person</p>
                                    </div>
                                </div>
                                <div className='row m-0'>
                                    <div className='col-6 col-sm-7'>
                                        <p className='m-0'>Paid Amount:</p>
                                    </div>
                                    <div className='col-6 col-sm-5'>
                                        <p className='m-0'>₹ {formdata?.total_amount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contact-details'>
                        <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>Venue: Elephant Beach, Andaman and Nicobar Islands</p>
                        <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>Contact: +91 99320-80340 for more details.</p>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <img className="logo" src="/sea gold.jpg" alt='logo'></img>
                    </div>
                    <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>(For more details contact:+91 99320-80340)</p>
                    <p className='m-0 text-center' style={{ fontSize: "0.8rem" }}>1st Floor, Sri Ram Complex, Opp: Old Taxi Stand, Aberdeen Bazaar,<br></br> Port Blair, Andaman and Nicobar Islands - 744101.</p>
                </div>
                <br></br>
                {
                    isAuthenticated ? <div className='d-flex justify-content-center align-items-center'>
                        <button className="customer-support" onClick={goBookingHistory}>View Booking History</button>
                    </div> : <div className='d-flex justify-content-center align-items-center'>
                        <button className="customer-support" onClick={goBookingHistory}>Go To Home Page</button>
                    </div>
                }
                {/* <PDFViewer width="100%" height="700px">
                    <MyDocument id={id.thankyou} bookingDetails={user} />
                </PDFViewer> */}
            </div>
        </Fragment>
    )
}

ThankYou.prototype = {
    getBookingDetailsById: PropTypes.func.isRequired,
    loadPaymentData: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    booking: state.booking
})

export default connect(mapStateToProps, { getBookingDetailsById, loadPaymentData })(ThankYou)