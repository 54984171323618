import { Input, InputNumber, message } from 'antd';
import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import "./Contact.scss";
import "./Home.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux';
import { contact } from '../actions/auth';
import { PropTypes } from 'prop-types';

const Contact = ({ contact }) => {

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Successfully Request Sent, Our team will contact you soon !!',
            className: 'custom-success-message',
        });
    };

    const navigate = useNavigate();

    const onClickAgencyRequest = () => {
        navigate('/agency-request')
    }

    const [contactDetails, setContactDetails] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: ""
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: ""
    });


    // handler for user inputs
    const handleUserInputChange = (event) => {
        setContactDetails({ ...contactDetails, [event.target.name]: event.target.value, });
    };

    // handler for user phone number input
    const handleUserPhoneNumberInput = (value) => {
        setContactDetails({ ...contactDetails, phoneNumber: value, });
    }


    // handle onsubmit 
    const onSubmit = (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!contactDetails.name) {
            newErrors.name = "Name is required";
        }
        if (!contactDetails.email) {
            newErrors.email = "Email ID is required";
        }
        if (!contactDetails.phoneNumber) {
            newErrors.phoneNumber = "Phone Number is required";
        }
        if (!contactDetails.message) {
            newErrors.message = "Message is required";
        }


        // Set all the errors at once
        setErrors(newErrors);

        const areErrorsEmpty = Object.values(newErrors).every((error) => !error);
        const areUserDetailsValid = Object.values(contactDetails).every((value) => value !== null && value !== "");

        if (areErrorsEmpty && areUserDetailsValid) {
            const { name, email, phoneNumber, message } = contactDetails;
            contact({ name, email, phoneNumber, message })
            console.log("Contact Details:", contactDetails);
            setContactDetails({
                name: "",
                email: "",
                phoneNumber: "",
                message: ""
            })
            success()
        } else {
            // Set all the errors at once
            setErrors(newErrors);
        }
    }


    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>Contact</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/contact'></link>
            </Helmet>
            <div className='home'>
                <section id='home_banner'>
                    <LazyLoadImage className='home_banner-img' src='/images/contact/andaman-sea-walk-bg-contact.jpg' alt='andaman nicobar islands'></LazyLoadImage>
                    <h1 className='home-main-title'>ANDAMAN & NICOBAR ISLANDS</h1>
                    <p className='home-main-sub-title'>(Biggest Seawalk in India - Sea Gold Sea Walk)</p>
                </section>
                <section id='contact-map'>
                    <div className='row m-0 p-1 p-md-3'>
                        <div className='col-md-6'>
                            <h3 className='heading' style={{ color: "#088395" }}>Contact Us</h3>
                            <p>Thank you for your interest in "Sea Gold Sea Walk"! <br></br> <br></br> We're here to answer any questions you might have about our breathtaking underwater experiences. Whether you're ready to book your adventure or simply seeking more information, feel free to get in touch with us. Your underwater journey awaits!</p>
                            <p><strong className='title'>Location:</strong> <br></br>
                                1st Floor, Sri Ram Complex, <br></br>
                                Opp: Old Taxi Stand, Aberdeen Bazaar,<br></br>
                                Port Blair,<br></br>
                                Andaman and Nicobar Islands - 744101.
                            </p>

                            <p><strong className='title'>Customer Care:</strong> <br></br>
                                <a href="tel:+91 7586904999" style={{ textDecoration: "none", color: "inherit" }}>+91 75869-04999</a> <br></br>
                                <a href="tel:+91 7695095641" style={{ textDecoration: "none", color: "inherit" }}>+91 76950-95641</a></p>

                            <p><strong className='title'>Complaints / Queries:</strong> <br></br>
                                <a href="tel:+91 9932080340" style={{ textDecoration: "none", color: "inherit" }}>+91 99320-80340</a></p>

                            <p><strong className='title'>Email:</strong> <br></br>
                                <a href="mailto:contact@seagoldseawalk.com">contact@seagoldseawalk.com</a></p>
                        </div>
                        <div className='col-md-6'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.3721484331527!2d92.73658307826045!3d11.668016312591343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3088950b6dd09d9d%3A0x7e3c83f924312f88!2sAberdeen%20Bazar%2C%20Aberdeen%2C%20Port%20Blair%2C%20Andaman%20and%20Nicobar%20Islands%20744101!5e0!3m2!1sen!2sin!4v1697026936551!5m2!1sen!2sin" width="100%" height="100%" style={{ border: "none" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </section>
                <section id='contact-form-section'>

                    <div className='form-input d-flex justify-content-center align-items-center'>
                        <button className='form-btn w-auto' style={{ fontSize: "1.2rem", fontWeight: "600" }} onClick={onClickAgencyRequest}>Agency Request</button>
                    </div>
                    <br></br>
                    <h1 className='heading text-center'>Get in Touch</h1>
                    <p className='text-center'>Have a question? Need more details about our sea walking tours? <br></br> Our team is more than happy to assist you. Please fill out the form below, and we'll get back to you as soon as possible.</p>
                    <form onSubmit={(e) => onSubmit(e)} className='contact-form mt-5'>
                        <div className='form-input'>
                            <label>Name: <span style={{ color: "red" }}>*</span></label>
                            <Input name='name' placeholder="Name" autoComplete="none" value={contactDetails.name} onChange={(e) => handleUserInputChange(e)} />
                            {errors.name && <p className="error">* {errors.name}</p>}
                        </div>
                        <div className='form-input'>
                            <label>Email: <span style={{ color: "red" }}>*</span></label>
                            <Input name='email' placeholder="Email" type='email' autoComplete="none" value={contactDetails.email} onChange={(e) => handleUserInputChange(e)} />
                            {errors.email && <p className="error">* {errors.email}</p>}
                        </div>
                        <div className='form-input'>
                            <label>Phone Number: <span style={{ color: "red" }}>*</span></label>
                            <InputNumber name='phoneNumber' min={1} style={{ width: "100%" }} type='number' placeholder="Phone Number" autoComplete="none" value={contactDetails.phoneNumber} onChange={(value) => handleUserPhoneNumberInput(value)} />
                            {errors.phoneNumber && <p className="error">* {errors.phoneNumber}</p>}
                        </div>
                        <div className='form-input'>
                            <label>Message: <span style={{ color: "red" }}>*</span></label>
                            <Input.TextArea name='message' rows={4} placeholder="Message" autoComplete="none" value={contactDetails.message} onChange={(e) => handleUserInputChange(e)} />
                            {errors.message && <p className="error">* {errors.message}</p>}
                        </div>
                        <div className='form-input d-flex justify-content-center align-items-center'>
                            <button className='form-btn w-auto' type='submit'>Submit</button>
                        </div>
                    </form>
                </section>
            </div>
            {contextHolder}
            <Footer />
        </Fragment>
    )
}

Contact.prototype = {
    contact: PropTypes.func.isRequired
}

export default connect(null, { contact })(Contact);