import React, { useEffect, useState } from 'react';
import "./Footer.scss";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Footer = () => {

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div>
      <footer>
        <section className="pt-0 pb-3" id="footer-section">
          <div className="w-100 m-0 pt-4">

            <div className="row justify-content-center circle-blend-right circle-danger m-0">
              <div className="col-md-6 p-0 text-start text-md-start">
                <div className="address-inset p-0">
                  <h6 className="my-2 fw-bold fs-0">Sea Gold Sea Walk</h6>
                  <p className="fs--1 m-0 fw-light">1st Floor, Sri Ram Complex, Opp: Old Taxi Stand, Aberdeen Bazaar, Port Blair,<br></br> Andaman and Nicobar Islands - 744101.</p>
                  <a href="tel:+91 7586904999" style={{ textDecoration: "none" }}> <i className="fa-solid fa-phone fa-sm me-1"></i>
                    +91
                    75869-04999 </a>
                  <a href="tel:+91 7695095641" style={{ textDecoration: "none" }}> <i className="fa-solid fa-phone fa-sm me-1"></i>+91 76950-95641</a>
                </div>
              </div>
              <div className="col-md-6 row m-0 p-0">
                <div className="col-md-auto col-lg-auto p-0 ms-auto me-auto" style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <h5 className="my-2 fw-bold fs-lg-3" style={{ color: "white" }}>Follow Us On</h5>
                  <div className="d-flex justify-content-center align-items-center">
                    <ul className="list-unstyled list-inline my-1 d-flex">
                      <li className="list-inline-item"><a className="text-decoration-none" href="#"><i
                        className="fa-brands fa-facebook-f fa-lg" style={{ color: "white" }}></i></a></li>
                      <li className="list-inline-item"><a className="text-decoration-none" href="#"><i
                        className="fa-brands fa-twitter fa-lg" style={{ color: "white" }}></i></a></li>
                      <li className="list-inline-item"><a className="text-decoration-none" href="#"><i
                        className="fa-brands fa-linkedin-in fa-lg" style={{ color: "white" }}></i></a></li>
                      <li className="list-inline-item"><a className="text-decoration-none" href="#"><i
                        className="fa-brands fa-youtube fa-lg" style={{ color: "white" }}></i></a></li>
                      <li className="list-inline-item"><a className="text-decoration-none" href="#"><i
                        className="fa-brands fa-instagram fa-lg" style={{ color: "white" }}></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="w-100 footer__copy-rights pt-3">
          <div className="d-flex justify-content-md-center justify-content-start align-items-center">
            <p className="fs--1 m-0 fw-light" style={{ color: "white" }}>Khizar Ocean Logistics Private Limited. <br className='d-block d-sm-none'></br> 2023 - 2024 © All rights reserved.</p>
          </div>
          <div className="d-flex justify-content-md-center justify-content-start align-items-center">
            <a href='https://www.zeek.digital' target="_blank" className="fs--1 m-0 fw-light" style={{ color: "white" }}>Powered By <LazyLoadImage src='/images/contact/zeek-fav-icon.png' alt='zeek-digital' className='footer-zeek'></LazyLoadImage> <strong style={{ color: "white", fontWeight: "bold" }}>Zeek.Digital</strong></a>
          </div>
          <div className="d-flex justify-content-md-center justify-content-end align-items-center">
            <p className="fs--1 m-0 fw-light"> <Link to="/privacy-policy" style={{ color: "white" }}>Privacy Policy</Link> | <Link to="/terms-and-conditions" style={{ color: "white" }}>Terms and Conditions</Link> </p>
          </div>
        </section>
      </footer>
      <a href="#" className={`rocket-button ${isVisible ? "display" : "hide"}`}
        onClick={scrollToTop}>
        <LazyLoadImage src='/images/up-arrow.png' alt='scroll-up'></LazyLoadImage>
      </a>
    </div>
  )
}
