import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadPaymentData } from './../actions/booking';
import { PropTypes } from 'prop-types';

const RedirectURL = ({ loadPaymentData }) => {

    useEffect(() => {
        loadPaymentData()
    }, loadPaymentData)


    console.log("Redirect page")

    return (
        <div>RedirectURL</div>
    )
}

RedirectURL.prototype = {
    loadPaymentData: PropTypes.func.isRequired
}

export default connect(null, { loadPaymentData })(RedirectURL);