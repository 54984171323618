import { Input, InputNumber, message } from 'antd';
import React, { Fragment, useState } from 'react';
import Navbar from '../../components/layout/Navbar';
import "./Profile.css";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { updateUserProfile } from './../../actions/auth';
import { Navigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async';

const Profile = ({ auth: { user, isAuthenticated }, updateUserProfile }) => {

    const loggedUser = isAuthenticated ? user : {};

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Profile updated successfully !!',
            className: 'custom-success-message',
        });
    };

    const [updateProfileDetails, setUpdateProfileDetails] = useState({
        id: loggedUser.id,
        name: loggedUser.user_name,
        email: loggedUser.email,
        phoneNumber: loggedUser.phone_number,
        city: loggedUser.city ? loggedUser.city : "",
        state: loggedUser.state ? loggedUser.state : "",
        country: loggedUser.country ? loggedUser.country : ""
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        city: "",
        mismatchPassword: "",
    });


    // handler for user inputs
    const handleUserInputChange = (event) => {
        setUpdateProfileDetails({ ...updateProfileDetails, [event.target.name]: event.target.value, });
    };

    // handler for user phone number input
    const handleUserPhoneNumberInput = (value) => {
        setUpdateProfileDetails({ ...updateProfileDetails, phoneNumber: value, });
    }


    // handle onsubmit 
    const onSubmit = (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!updateProfileDetails.name) {
            newErrors.name = "Name is required";
        }
        if (!updateProfileDetails.email) {
            newErrors.email = "Email ID is required";
        }
        if (!updateProfileDetails.phoneNumber) {
            newErrors.phoneNumber = "Phone Number is required";
        }
        if (!updateProfileDetails.city) {
            newErrors.city = "City is required";
        }

        // Set all the errors at once
        setErrors(newErrors);

        const areErrorsEmpty = Object.values(newErrors).every((error) => !error);

        if (areErrorsEmpty) {
            const { name, email, phoneNumber, city, state, country } = updateProfileDetails;
            updateUserProfile({ name, city, state, country })
            success()
        } else {
            // Set all the errors at once
            setErrors(newErrors);
        }
    }

    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>Profile</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/profile'></link>
            </Helmet>
            <section id='home_banner'>
                <LazyLoadImage className='home_banner-img' src='/images/login/bg-5.png' alt='andaman nicobar islands'></LazyLoadImage>
                <h1 className='home-main-title'>ANDAMAN & NICOBAR ISLANDS</h1>
                <p className='home-main-sub-title'>(Biggest Seawalk in India - Sea Gold Sea Walk)</p>
            </section>
            <div className='update-profile'>
                <h1 className='heading text-center'>Update Your Profile</h1>
                <p className='text-center'>Welcome to your profile section of Sea Gold Sea Walk. Here, you can view & edit your profile. </p>
                <br></br>
                <form onSubmit={(e) => onSubmit(e)} className='profile-form'>
                    <div className='row m-0'>
                        <div className='col-md-6'>
                            <div className='form-input p-1'>
                                <label>Name: <span style={{ color: "red" }}>*</span></label>
                                <Input name='name' placeholder="Name" autoComplete="none" type='text' value={updateProfileDetails.name} onChange={(e) => handleUserInputChange(e)} />
                                {errors.name && <p className="error">* {errors.name}</p>}
                            </div>
                            <div className='form-input p-1'>
                                <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                                <Input name='email' placeholder="Email Id" autoComplete="none" type='email' disabled value={updateProfileDetails.email} onChange={(e) => handleUserInputChange(e)} />
                                {errors.email && <p className="error">* {errors.email}</p>}
                            </div>
                            <div className='form-input p-1'>
                                <label>Phone Number: <span style={{ color: "red" }}>*</span></label>
                                <InputNumber name='phoneNumber' min={1} style={{ width: "100%" }} type='number' disabled upHandler={null} downHandler={null} placeholder="Phone Number" autoComplete="none" value={updateProfileDetails.phoneNumber} onChange={(value) => handleUserPhoneNumberInput(value)} />
                                {errors.phoneNumber && <p className="error">* {errors.phoneNumber}</p>}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-input p-1'>
                                <label>City: <span style={{ color: "red" }}>*</span></label>
                                <Input name='city' placeholder="City" autoComplete="none" type='text' value={updateProfileDetails.city} onChange={(e) => handleUserInputChange(e)} />
                            </div>
                            <div className='form-input p-1'>
                                <label>State:</label>
                                <Input name='state' placeholder="State" autoComplete="none" type='text' value={updateProfileDetails.state} onChange={(e) => handleUserInputChange(e)} />
                            </div>
                            <div className='form-input p-1'>
                                <label>Country:</label>
                                <Input name='country' placeholder="Country" autoComplete="none" type='text' value={updateProfileDetails.country} onChange={(e) => handleUserInputChange(e)} />
                            </div>
                            <br></br>
                        </div>

                        <div className='form-input d-flex justify-content-center align-items-center'>
                            <button className='form-btn w-auto'>Save Changes</button>
                        </div>
                    </div>
                </form>
            </div>
            {contextHolder}
        </Fragment>
    )
}

Profile.prototype = {
    updateUserProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { updateUserProfile })(Profile);