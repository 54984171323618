import { Input, InputNumber, Select } from 'antd';
import PropTypes from "prop-types";
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Footer } from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import Button from '../components/ui/Button';
import { setFormdata } from './../actions/formActions';
import "./Booking.scss";
import { updateUserProfile, guestUser } from './../actions/auth';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'
import { makeBooking, getBookingDetailsById } from './../actions/booking';
import useRazorpay from "react-razorpay";


const Booking = ({ makeBooking, formdata, getBookingDetailsById, auth: { user, isAuthenticated }, updateUserProfile, guestUser }) => {

    const [Razorpay] = useRazorpay();
    const id = useParams();

    const navigate = useNavigate();

    const [disabled, setDisabled] = useState(isAuthenticated);
    const loggedUser = isAuthenticated ? user : {};

    const [bookingDetails, setBookingDetails] = useState({
        id: formdata.id,
        seawalkdate: new Date(formdata.date).toLocaleDateString(),
        bookedSlot: formdata.slot,
        travelers: formdata.travelers,
        amount: formdata.travelers * 3500,
        gst: ((formdata.travelers * 3500) * 18) / 100,
        // totalAmount: (bookingformdata.travelers * 3500) + ((bookingformdata.travelers * 3500) * 18) / 100,
        totalAmount: (formdata.travelers * 3500),
        travelersDetails: [],
        userDetails: {
            userName: isAuthenticated ? loggedUser.user_name : '',
            userEmailId: isAuthenticated ? loggedUser.email : '',
            userPhoneNumber: isAuthenticated ? loggedUser.phone_number : '',
            userCity: isAuthenticated ? loggedUser.city : ''
        }
    });

    const [errors, setErrors] = useState();

    // handler for user phone number input
    const handleUserPhoneNumber = (value, name) => {
        setBookingDetails({
            ...bookingDetails,
            userDetails: {
                ...bookingDetails.userDetails,
                userPhoneNumber: value,
            },
        });
    }

    // handler for user inputs
    const handleUserInputChange = (event) => {
        const { name, value } = event.target;

        setBookingDetails({
            ...bookingDetails,
            userDetails: {
                ...bookingDetails.userDetails,
                [name]: value,
            },
        });
    };

    // Function to handle traveler name input changes
    const handleTravelerName = (event, index) => {
        const { name, value } = event.target;
        const updatedTravelersDetails = [...bookingDetails.travelersDetails];
        if (!updatedTravelersDetails[index]) {
            updatedTravelersDetails[index] = {};
        }
        updatedTravelersDetails[index][name] = value;

        setBookingDetails({
            ...bookingDetails,
            travelersDetails: updatedTravelersDetails,
        });
    }

    // Function to handle traveler gender and age input changes
    const handleTravelerInputChange = (value, index, inputName) => {
        const updatedTravelersDetails = [...bookingDetails.travelersDetails];
        if (!updatedTravelersDetails[index]) {
            updatedTravelersDetails[index] = {};
        }
        updatedTravelersDetails[index][inputName] = value;

        setBookingDetails({
            ...bookingDetails,
            travelersDetails: updatedTravelersDetails,
        });
    };


    // display no of rows for travelers details input
    const travelerRows = Array.from({ length: bookingDetails.travelers }, (_, index) => (
        <div key={index} className='row m-0'>
            <div className='col-md-1 p-0'>
                <div className='form-input'>
                    <p className='form-Sno m-0'>{index + 1}.</p>
                </div>
            </div>
            <div className='col-md-5 p-0'>
                <div className='form-input'>
                    <Input name={`travelerName`} placeholder="Name *" autoComplete="none" onChange={(event) => handleTravelerName(event, index)} required />
                    {
                        errors && errors.map((error, i) => {
                            if (i === index) {
                                return <>
                                    {error.name && <p className='error'>* {error.name}</p>}
                                </>
                            }
                        })
                    }
                </div>
            </div>
            <div className='col-6 col-md-3 p-0'>
                <div className='form-input'>
                    <Select
                        name={`travelerGender`}
                        placeholder="Gender *"
                        style={{ width: '100%' }}
                        onChange={(value) => handleTravelerInputChange(value, index, 'travelerGender')}
                        options={[
                            { value: 'Male', label: 'Male' },
                            { value: 'Female', label: 'Female' },
                            { value: 'Other', label: 'Other' },
                        ]}
                    />
                    {
                        errors && errors.map((error, i) => {
                            if (i === index) {
                                return <>
                                    {error.gender && <p className='error'>* {error.gender}</p>}
                                </>
                            }
                        })
                    }
                </div>
            </div>
            <div className='col-6 col-md-3 p-0'>
                <div className='form-input'>
                    <InputNumber
                        name={`travelerAge`}
                        min={1}
                        max={100}
                        style={{ width: "100%" }}
                        placeholder="Age *"
                        autoComplete="none"
                        type='number'
                        onChange={(value) => handleTravelerInputChange(value, index, 'travelerAge')} />
                    {
                        errors && errors.map((error, i) => {
                            if (i === index) {
                                return <>
                                    {error.age && <p className='error'>* {error.age}</p>}
                                </>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    ));

    const bookingHistory = JSON.parse(localStorage.getItem("booking history"))
    const registedUsers = JSON.parse(localStorage.getItem("register users"));
    const objectIndex = registedUsers && registedUsers.findIndex(obj => obj.id === loggedUser.id);

    function generateBookingID() {
        // Replace this with a unique identifier
        const uniqueString = "SG";
        const date = `${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`
        const time = `${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}${new Date().getMilliseconds()}`
        const randomDigits = Math.floor(Math.random() * 1000).toString().padStart(5, '0'); // Generate random 3-digit number and pad with zeros
        const bookingID = `${uniqueString}${date}${time}`;
        return bookingID;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const travelerDetailsArray = bookingDetails.travelersDetails.map((traveler, index) => {
            // Create a new object with the traveler's details
            const travelerDetails = {
                name: !traveler.travelerName ? 'Name is required' : null,
                gender: !traveler.travelerGender ? 'Gender is required' : null,
                age: !traveler.travelerAge ? 'Age is required' : null,
            };
            return travelerDetails;
        });
        if (bookingDetails.travelersDetails.length === 0) {
            const travelerDetails = {
                name: 'Name is required',
                gender: 'Gender is required',
                age: 'Age is required',
            };
            setErrors([{ ...travelerDetails }]);
            travelerDetailsArray = [{ ...travelerDetails }]
        }
        // Check if all values in the array of objects are null
        const allValuesAreNull = travelerDetailsArray.every((obj) =>
            Object.values(obj).every((value) => value === null)
        );

        // if all error values are null then submit form else display error
        if (allValuesAreNull) {

            console.log('Form data submitted:', bookingDetails);
            const formDataWithId = {
                ...bookingDetails,
                bookingID: generateBookingID(),
                bookedOn: new Date(),
            };
            const { bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails } = formDataWithId;
            if (isAuthenticated) {
                const { bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails } = formDataWithId;
                makeBooking({ bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails });

                // getBookingDetailsById(bookingID)
                // navigate('/thankyou/' + bookingID);
            } else {
                const { userName, userEmailId, userPhoneNumber, userCity } = formDataWithId.userDetails;
                const { bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails } = formDataWithId;
                guestUser({ name: userName, email: userEmailId, password: null, phoneNumber: userPhoneNumber, city: userCity, userType: "User", status: "User", bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails });
                // navigate('/thankyou/' + bookingID);
            }
            // navigate('/thankyou/' + bookingID);
            setErrors([]);

        } else {
            setErrors(travelerDetailsArray);
            if (bookingDetails.travelersDetails.length === 0) {
                const travelerDetails = {
                    name: 'Name is required',
                    gender: 'Gender is required',
                    age: 'Age is required',
                };
                setErrors([{ ...travelerDetails }]);
            } else {
                setErrors(travelerDetailsArray)
            }
        }

    };

    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>Booking</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/booking/:booking'></link>
            </Helmet>
            <form onSubmit={handleSubmit}>
                <div style={{ position: "relative" }}>
                    <section id='booking'>
                        <div className='row m-0'>
                            <div className='col-lg-8 p-4'>
                                <h1 className='details_title'>User Details</h1>
                                <div id='user-details' className='p-2 user-details'>
                                    <div className='w-100'>
                                        <div className='row m-0'>
                                            <div className='col-md-6 p-0'>
                                                <div className='form-input'>
                                                    <label>Name: <span style={{ color: "red" }}>*</span></label>
                                                    <Input name='userName' placeholder="Name" autoComplete="none" disabled={disabled} value={bookingDetails.userDetails.userName} onChange={handleUserInputChange} required />
                                                </div>
                                            </div>
                                            <div className='col-md-6 p-0'>
                                                <div className='form-input'>
                                                    <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                                                    <Input name='userEmailId' placeholder="Email Id" autoComplete="none" type='email' disabled={disabled} value={bookingDetails.userDetails.userEmailId} onChange={handleUserInputChange} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-0'>
                                            <div className='col-md-6 p-0'>
                                                <div className='form-input'>
                                                    <label>Phone Number: <span style={{ color: "red" }}>*</span></label>
                                                    <InputNumber name='userPhoneNumber' min={1} style={{ width: "100%" }} type='number' placeholder="Phone Number" autoComplete="none" disabled={disabled} value={bookingDetails.userDetails.userPhoneNumber} onChange={handleUserPhoneNumber} required />
                                                </div>
                                            </div>
                                            <div className='col-md-6 p-0'>
                                                <div className='form-input'>
                                                    <label>City: <span style={{ color: "red" }}>*</span></label>
                                                    <Input name='userCity' placeholder="City" autoComplete="none" disabled={disabled} value={bookingDetails.userDetails.userCity} onChange={handleUserInputChange} required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <h1 className='details_title'>Travellers Details</h1>
                                <div id='travellers-details' className='p-2 user-details'>
                                    <div className='w-100'>
                                        <div className='row m-0 form-heading d-none d-md-flex'>
                                            <div className='col-md-1 p-0'>
                                                <div className='form-input'>
                                                    <label>S.No:</label>
                                                </div>
                                            </div>
                                            <div className='col-md-5 p-0'>
                                                <div className='form-input'>
                                                    <label>Name:</label>
                                                </div>
                                            </div>
                                            <div className='col-md-3 p-0'>
                                                <div className='form-input'>
                                                    <label>Gender:</label>
                                                </div>
                                            </div>
                                            <div className='col-md-3 p-0'>
                                                <div className='form-input'>
                                                    <label>Age:</label>
                                                </div>
                                            </div>
                                        </div>
                                        {travelerRows}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 p-4' id='booking-container'>
                                <div id='booking-section'>
                                    <div>
                                        <h1 className='details_title text-center'>Booking Details</h1>
                                        <div className='p-1 form-date row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>Sea Walk Date:</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>{bookingDetails.seawalkdate}</p></div>
                                        </div>
                                        <div className='p-1 form-slot row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>Slot (Sea Walk Time):</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>{bookingDetails.bookedSlot}</p></div>
                                        </div>
                                        <div className='p-1 form-travellers row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>No.of Travellers:</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>{bookingDetails.travelers} person</p></div>
                                        </div>
                                        <div className='p-1 row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>Actual Amount ({bookingDetails.travelers} x 3500):</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>₹ {bookingDetails.amount}</p></div>
                                        </div>
                                        {/* <div className='p-1 row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>GST (18%):</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>₹ {bookingDetails.gst}</p></div>
                                        </div> */}
                                        <div className='row m-1 amount-to-pay'>
                                            <div className="col-7 p-0 m-0"><p className='m-0 preview-amt'>Amount To Pay</p></div>
                                            <div className="col-5 p-0 m-0"><p className='m-0 preview-amt'>₹ {bookingDetails.totalAmount}</p></div>
                                        </div>
                                        <div className='p-1 p-0'>
                                            <Button name="Pay Now" type="submit" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <nav className="fixed-bottom bottom-button">
                    <div className='form-button h-100'>
                        <button className='submit-button' type='submit'>
                            <p className='btn-text'>{bookingDetails.totalAmount ? `₹ ${bookingDetails.totalAmount}/-` : ""}</p>
                            <p className='btn-text'>Pay Now <LazyLoadImage style={{ width: "35px", marginLeft: "10px" }} src='/images/right-arrow.png'></LazyLoadImage></p>
                        </button>
                    </div>
                </nav>
            </form>
            <Footer />
        </Fragment>
    )
}

Booking.prototype = {
    makeBooking: PropTypes.func.isRequired,
    getBookingDetailsById: PropTypes.func.isRequired,
    formdata: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    guestUser: PropTypes.func.isRequired
}


const mapStateToProps = (state) => {
    return {
        formdata: state.formReducer.formdata,
        auth: state.auth
    }
}

export default connect(mapStateToProps, { makeBooking, getBookingDetailsById, updateUserProfile, guestUser })(Booking)