import React from 'react';
import styles from './Button.module.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Button = (props) => {
  return (
    <button className={styles.button} {...props} onClick={props.onClick}>{props.name} <LazyLoadImage style={{ width: "35px", marginLeft: "10px" }} src='/images/right-arrow.png'></LazyLoadImage></button>
  )
}

export default Button;