import { Input } from 'antd';
import PropTypes from "prop-types";
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import { login } from './../actions/auth';
import "./Login.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'

const Login = ({ login, isAuthenticated }) => {

    const navigate = useNavigate();

    const [loginDetails, setLoginDetails] = useState({
        email: "",
        password: ""
    })

    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });

    const onChange = (e) => {
        setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(loginDetails)
        const { email, password } = loginDetails;

        let newErrors = {};

        if (!email) {
            newErrors.email = "Invalid Email";
        }
        if (!password) {
            newErrors.password = "Invalid Password";
        }
        // Set all the errors at once
        setErrors(newErrors);

        const areErrorsEmpty = Object.values(newErrors).every((error) => !error);
        if (areErrorsEmpty) {
            login({ email, password });
        } else {
            setErrors(newErrors);
        }

    }

    if (isAuthenticated) {
        return <Navigate to='/' />
    }

    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>Login</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/lgoin'></link>
            </Helmet>
            <div id='login' className='h-100'>
                <LazyLoadImage className='login-bg' src='/images/login/sunset-beach.png' alt='sunset-beach-background'></LazyLoadImage>
                <div className='login-form'>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <h1 className='heading text-center'>Sign In</h1>
                        <div className='form-input'>
                            <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                            <Input name='email' placeholder="Email Id" autoComplete="none" type='email' value={loginDetails.email} required onChange={(e) => onChange(e)} />
                            {errors.email && <p className="error">* {errors.email}</p>}
                        </div>
                        <div className='form-input'>
                            <label>Password: <span style={{ color: "red" }}>*</span></label>
                            <Input name='password' placeholder="Password" autoComplete="none" type='password' value={loginDetails.password} required onChange={(e) => onChange(e)} />
                            {errors.password && <p className="error">* {errors.password}</p>}
                        </div>
                        <div className='form-input'>
                            <button className='form-btn'>Login</button>
                        </div>
                        <div className='form-input'>
                            <p className='form-text'>New to Andaman Sea Walk ? <Link to="/register" style={{ color: "#05BFDB" }}>Create Account</Link></p>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}


Login.prototype = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
}
)

export default connect(mapStateToProps, { login })(Login)