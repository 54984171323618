import { Input, InputNumber, message } from 'antd';
import React, { Fragment, useState } from 'react';
import Navbar from '../components/layout/Navbar';
import "./users/Profile.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux';
import { agentRequest } from './../actions/auth';
import { PropTypes } from 'prop-types';
import emailjs from '@emailjs/browser';

const AgencyRequest = ({ agentRequest }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Successfully Request Sent, Our team will contact you soon !!',
            className: 'custom-success-message',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: "Something went wrong! can't send email !",
            className: 'custom-error-message',
        });
    };

    const [agencyRequestDetails, setAgencyRequestDetails] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        agencyName: "",
        agencyGSTnumber: "",
        city: "",
        state: "",
        country: ""
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        agencyName: "",
        city: "",
    });


    // handler for user inputs
    const handleUserInputChange = (event) => {
        setAgencyRequestDetails({ ...agencyRequestDetails, [event.target.name]: event.target.value, });
    };

    // handler for user phone number input
    const handleUserPhoneNumberInput = (value) => {
        setAgencyRequestDetails({ ...agencyRequestDetails, phoneNumber: value, });
    }

    const agencyRequestList = JSON.parse(localStorage.getItem('Agency Requests'))
    // handle onsubmit 
    const onSubmit = (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!agencyRequestDetails.name) {
            newErrors.name = "Name is required";
        }
        if (!agencyRequestDetails.email) {
            newErrors.email = "Email ID is required";
        }
        if (!agencyRequestDetails.phoneNumber) {
            newErrors.phoneNumber = "Phone Number is required";
        }
        if (!agencyRequestDetails.agencyName) {
            newErrors.agencyName = "Agency Name is required";
        }
        if (!agencyRequestDetails.city) {
            newErrors.city = "City is required";
        }

        // Set all the errors at once
        setErrors(newErrors);

        const areErrorsEmpty = Object.values(newErrors).every((error) => !error);

        if (areErrorsEmpty) {
            console.log("Agency Request Details:", agencyRequestDetails, process.env.REACT_APP_PUBLIC_KEY);
            agentRequest(agencyRequestDetails);

            setAgencyRequestDetails({
                name: "",
                email: "",
                phoneNumber: "",
                agencyName: "",
                agencyGSTnumber: "",
                city: "",
                state: "",
                country: ""
            })
            success()
        } else {
            // Set all the errors at once
            setErrors(newErrors);
        }
    }


    return (
        <Fragment>
            <Navbar />
            <Helmet>
                <title>Agency Request</title>
                <meta name="description" content="This is what you want to show as the page content in the Google SERP Listing" />
                <link rel='canonical' href='/agency-request'></link>
            </Helmet>
            <section id='home_banner'>
                <LazyLoadImage className='home_banner-img' src='/images/login/bg-7.png' alt='andaman nicobar islands'></LazyLoadImage>
                <h1 className='home-main-title'>ANDAMAN & NICOBAR ISLANDS</h1>
                <p className='home-main-sub-title'>(Biggest Seawalk in India - Sea Gold Sea Walk)</p>
            </section>
            <div className='update-profile'>
                <div>
                    <h1 className='heading text-center'>Agency Request</h1>
                    <p className='text-center'>Are you a Travel agent / agency, looking to partner with our "Sea Gold Sea Walk" for taking booking for your Customers? <br></br> Fill out the below form and our team will get in touch with you shortly.</p>
                    <br></br>
                    <form onSubmit={(e) => onSubmit(e)} className='profile-form'>
                        <div className='row m-0'>
                            <div className='col-md-6'>
                                <div className='form-input p-1'>
                                    <label>Name: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='name' placeholder="Name" autoComplete="none" type='text' value={agencyRequestDetails.name} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.name && <p className="error">* {errors.name}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='email' placeholder="Email Id" autoComplete="none" type='email' value={agencyRequestDetails.email} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.email && <p className="error">* {errors.email}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>Phone Number: <span style={{ color: "red" }}>*</span></label>
                                    <InputNumber name='phoneNumber' min={1} style={{ width: "100%" }} type='number' placeholder="Phone Number" autoComplete="none" value={agencyRequestDetails.phoneNumber} onChange={(value) => handleUserPhoneNumberInput(value)} />
                                    {errors.phoneNumber && <p className="error">* {errors.phoneNumber}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>Agency Name: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='agencyName' placeholder="Agency Name" autoComplete="none" type='text' value={agencyRequestDetails.agencyName} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.agencyName && <p className="error">* {errors.agencyName}</p>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-input p-1'>
                                    <label>Agency GST Number:</label>
                                    <Input name='agencyGSTnumber' style={{ width: "100%" }} placeholder="Agency GST Number" autoComplete="none" pattern='[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}' value={agencyRequestDetails.agencyGSTnumber} onChange={(e) => handleUserInputChange(e)} />
                                </div>
                                <div className='form-input p-1'>
                                    <label>City: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='city' placeholder="City" autoComplete="none" type='text' value={agencyRequestDetails.city} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.city && <p className="error">* {errors.city}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>State:</label>
                                    <Input name='state' placeholder="State" autoComplete="none" type='text' value={agencyRequestDetails.state} onChange={(e) => handleUserInputChange(e)} />
                                </div>
                                <div className='form-input p-1'>
                                    <label>Country:</label>
                                    <Input name='country' placeholder="Country" autoComplete="none" type='text' value={agencyRequestDetails.country} onChange={(e) => handleUserInputChange(e)} />
                                </div>
                                <br></br>
                            </div>

                            <div className='form-input d-flex justify-content-center align-items-center'>
                                <button className='form-btn w-auto' type='submit'>Send Request</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {contextHolder}
        </Fragment>
    )
}

AgencyRequest.prototype = {
    agentRequest: PropTypes.func.isRequired
}

export default connect(null, { agentRequest })(AgencyRequest);