import { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import React, { Fragment, useState } from 'react';
import MyDocument from '../components/ui/DownloadPDF';
import "./ThankYou.scss";
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BookingFailure = ({ auth: { isAuthenticated, user } }) => {

    const id = useParams();
    const navigate = useNavigate();

    const loggedUser = isAuthenticated ? user.user : {};
    const reversedData = loggedUser.bookingHistory ? [...loggedUser.bookingHistory].reverse() : [];
    const BookingDetails = JSON.parse(localStorage.getItem("booking details")) || null;

    const findBookingById = (id) => {
        return reversedData.find((booking) => booking.id === id);
    };

    const formdata = isAuthenticated ? findBookingById(id.bookingFailed) : BookingDetails;

    const options = {
        timeZone: 'Asia/Kolkata', // Set the time zone to IST
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };


    const goBookingHistory = () => {
        localStorage.removeItem("booking details");
        navigate(isAuthenticated ? '/booking-history' : '/')
    }

    return (
        <Fragment>
            <div id='thank-you' className='h-100'>
                <LazyLoadImage className='thank-you-bg' src='/images/thankyou/sea-bg.jpg' alt='sea-background'></LazyLoadImage>
                <div id='booking-success'>
                    <h1 className='booking-success-text text-center p-0'><LazyLoadImage className='success-img' src='/images/thankyou/failure.png' alt='successfully-booked'></LazyLoadImage>Oops! Payment Failed!</h1>
                </div>
                <p className='text-center mb-3 m-sm-0' style={{ color: "red" }}>Your booking for {formdata.travelers} {formdata.travelers > 1 ? "persons" : "person"} on {(new Date(formdata.seawalkdate).toDateString())} has been failed.</p>
                <p className='text-center mb-3 m-sm-0'>Please try again</p>
                <br></br>
                <div className='d-flex justify-content-center align-items-center'>
                    <button className="customer-support" onClick={goBookingHistory}>Go To Home Page</button>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(BookingFailure)