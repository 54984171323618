import { AGENT_REQUEST_FAIL, AGENT_REQUEST_SENT, AUTH_ERROR, ENQUIRY_FAIL, ENQUIRY_SENT, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, PROFILE_UPDATED, PROFILE_UPDATED_FAIL, REGISTER_FAIL, REGISTER_SUCCESS, USER_LOADED } from "../actions/types";

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    loading: false,
    user: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            }
        case ENQUIRY_SENT:
        case ENQUIRY_FAIL:
        case REGISTER_SUCCESS:
        case AGENT_REQUEST_SENT:
            return {
                ...state,
                ...payload,
                isAuthenticated: false,
                loading: false,
            }
        case PROFILE_UPDATED:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
            }
        case LOGIN_SUCCESS:
            localStorage.setItem("token", payload.token)
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
            }
        case PROFILE_UPDATED_FAIL:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
            }
        case AUTH_ERROR:
        case REGISTER_FAIL:
        case AGENT_REQUEST_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem("token");
            localStorage.removeItem("registereduser");
            localStorage.removeItem("logged user");
            localStorage.removeItem("booking form details");
            localStorage.removeItem("booking history");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false
            }
        default:
            return state;
    }
}