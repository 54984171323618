import PropTypes from 'prop-types';
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";
import "./Navbar.scss";
import { message } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
  {
    type: 'divider',
  },
  {
    label: '3rd menu item',
    key: '3',
  },
];

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Logout successfully !!',
      className: 'custom-success-message',
    });
  };

  const [loadUser, setLoadUser] = useState(false);
  const onLogout = () => {
    success()
    logout()
  }

  const authLinks = (
    <>
      <Link to="/profile">Profile</Link>
      <Link to="/booking-history">Booking History</Link>
      <Link to="#!" onClick={onLogout}>Logout</Link>
    </>
  );

  const guestLinks = (
    <>
      <Link to="/login">Login</Link>
      <Link to="/register">Register</Link>
    </>
  )

  return (
    <Fragment>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand p-0">
            <LazyLoadImage className="logo" src="/images/navbar/sea-gold-logo.png" alt="logo" loading='eager' title='Sea Gold Sea Walk'></LazyLoadImage>
          </Link>
          <div className='d-flex d-lg-none'>
            <div className="dropdown p-0">
              <span><LazyLoadImage className="profile-img" src="/images/navbar/user-profile.png" alt="user profile" loading='eager' title='Sea Gold Sea Walk'></LazyLoadImage></span>
              <div className="dropdown-content">
                {!loading && (isAuthenticated ? authLinks : guestLinks)}
              </div>
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <LazyLoadImage className="hamburger-img" src="/images/navbar/hamburger.png" alt="hamburger-btn" loading='eager' title='Sea Gold Sea Walk'></LazyLoadImage>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="navbar-brand d-none" href="#">
                  Navbar
                </a>
              </li>
            </ul>
            <div className="d-flex">
              <ul className="navbar-nav w-100 mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/how-to-reach" className="nav-link">
                    How to Reach
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/book-sea-walk-in-andaman" className="nav-link active-menu">
                    Book Sea Walk
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact Us
                  </Link>
                </li>
                {/* <li className="nav-item">
                      <a className="nav-link" href="tel:+91 8946021376" style={{textDecoration:"none"}}> <i className="fa-solid fa-phone fa-md me-1" style={{color:"#0A4D68"}}></i></a>
                    </li> */}
                <li className="nav-item d-none d-lg-block">
                  <div className="dropdown nav-link">
                    <span><LazyLoadImage className="profile-img" src="/images/navbar/user-profile.png" alt="user profile" loading='eager' title='Sea Gold Sea Walk'></LazyLoadImage></span>
                    <div className="dropdown-content">
                      {!loading && (isAuthenticated ? authLinks : guestLinks)}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {contextHolder}
    </Fragment>
  );
};

Navbar.prototype = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logout })(Navbar);