import React, { Fragment } from 'react';
import styles from "./Card.module.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const WhyBestCard = (props) => {
  return (
    <div id='why-best-card' className={styles['why-best-card']} >
      <div className={styles['why-best-card_icon']}>
        <LazyLoadImage className={styles['why-best-card_icon-img']} src={props.src} alt='slot pricing' title='why we are best?' loading='eager'></LazyLoadImage>
      </div>
      <div className={styles['why-best-card_details']}>
        <h2 className={styles['why-best-card_details-title']}>{props.title}</h2>
        <p className={styles['why-best-card_details-text']}>{props.description}</p>
      </div>
    </div>
  )
}

export const ExploreAndamanCard = (props) => {
  return (
    <Fragment>
      <div className='d-flex flex-column align-items-center'>
        <div id='explore-andaman-card' className={styles['explore-andaman-card']}>
          <LazyLoadImage className={styles['explore-andaman-card-img']} src={props.src} alt={props.alt}></LazyLoadImage>
        </div>
        <p className={styles['explore-andaman-card-text']}>{props.name}</p>
      </div>
    </Fragment>

  )
}

export const AboutCard = (props) => {
  return (
    <div id='about-card' className={styles['about-card']} >
      <div className={styles['about-card_icon']}>
        <img className={styles['about-card_icon-img']} src={props.src} alt='slot pricing'></img>
      </div>
      <div className={styles['about-card_details']}>
        <h2 className={styles['about-card_details-title']}>{props.title}</h2>
        <p className={styles['about-card_details-text']}>{props.description}</p>
      </div>
    </div>
  )
}