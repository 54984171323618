import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { About } from './pages/About';
import Booking from './pages/Booking';
import Contact from './pages/Contact';
import Home from './pages/Home';
import SeaWalk from './pages/SeaWalk';
import ThankYou from './pages/ThankYou';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { loadUser } from './actions/auth';
import AgencyRequest from './pages/AgencyRequest';
import HowToReach from './pages/HowToReach';
import Login from './pages/Login';
import Register from './pages/Register';
import BookingHistory from './pages/users/BookingHistory';
import Profile from './pages/users/Profile';
import store from "./store";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import PrivateRoute from "./components/routing/PrivateRoute";
import BookingFailure from "./pages/BookingFailure";
import setAuthToken from "./utils/setAuthToken";
import { getBookingHistory } from "./actions/booking";
import RedirectURL from "./pages/RedirectURL";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {

  useEffect(() => {
    store.dispatch(loadUser());
    // store.dispatch(getBookingHistory());
  }, [])

  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <section className='container-fluid p-0 m-0 w-100 h-100'>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/about' element={<About />} />
              <Route path='/how-to-reach' element={<HowToReach />} />
              <Route path='/book-sea-walk-in-andaman' element={<SeaWalk />} />
              <Route path='/booking/:booking' element={<Booking />} />
              <Route path='/thankyou/:thankyou' element={<ThankYou />} />
              <Route path='/profile' element={<PrivateRoute component={Profile} />} />
              <Route path='/booking-history' element={<PrivateRoute component={BookingHistory} />} />
              <Route path="/agency-request" element={<AgencyRequest />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsConditions />} />
              <Route path="/booking-failure/:bookingFailed" element={<BookingFailure />} />
              <Route path="/redirect-url" element={<RedirectURL />} />
              <Route path='*' element={<Home />} />
            </Routes>
          </section>
        </BrowserRouter>
      </div>
    </Provider>

  );
}

export default App;
